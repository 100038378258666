import {IonList,IonItem,IonThumbnail, IonContent, IonHeader,IonButton,
  IonImg,useIonAlert,IonAlert,IonButtons,IonMenuButton, IonPage, IonTitle, IonToolbar,IonChip,IonLabel, useIonToast } from '@ionic/react';
import './AddPOS.css';
import 'axios'

import React, { useEffect, useState } from 'react'
import { API, graphqlOperation } from 'aws-amplify'
import { updateUser } from '../graphql/mutations'
import { listUsers } from '../graphql/queries'
import {
  useIonViewWillEnter,
} from '@ionic/react';
import axios from 'axios';
import * as subscriptions from '../graphql/subscriptions';
import Observable from 'zen-observable';

const AddPOS: React.FC = () => {

  useIonViewWillEnter(() => {
    posManager();
    //console.log('ionViewWillEnter event fired');
  });

  const [awsUserID, setawsUserID] = useState<any[]>([]);
  const [POSName, setPOSName] = useState<string>();
  const [showAlert, hideAlert] = useIonAlert();
  const [present, dismiss] = useIonToast();


   // Initial data pull when the view renders
   async function posManager() {
    console.log("POS Manager Starter: ");
    try {
      const initialQueryData: any = await API.graphql(graphqlOperation(listUsers));
      const tmpPOSname = initialQueryData.data.listUsers.items[0].pos_name;
      setPOSName(tmpPOSname)
      console.log("POS Selected: ",tmpPOSname);
      
      const tmpAWSid = initialQueryData.data.listUsers.items[0].id;
      setawsUserID(tmpAWSid);

    } catch (err) {
      console.log("Error Managing POS Name: ",err);
    }
  }
  
   
// Subscriptions to new user data
useEffect(()=>{
    
  const subData = API.graphql(graphqlOperation(subscriptions.updatedOrder));
  if (subData instanceof Observable) {
    const sub = subData.subscribe({
      next: (newData) => {
        const tmp_pos_name = newData.value.data.updatedOrder.pos_name
        setPOSName(tmp_pos_name);
        
      },
      error(err) { console.log(`Finished with error: ${ err }`) },
      complete() { console.log('Finished') }
    });
  
  return()=>{
    sub instanceof Observable && sub.unsubscribe()
  };
}
},[]);


  useEffect(()=>{
    posManager();
  },[]);

// Change from Ready to Delivered
async function deletePOS() {
  //console.log("------------------------------");
  try {
   
    
    const putResultData: any = await API.graphql(
      graphqlOperation(updateUser, {
        input: {"id":awsUserID,
        orders: [],
        merchant_id:'None',
        pos_name:'None',
        merchant_street:'',
        merchant_city:'',
        merchant_zipCode:'',
        phone:'',
        merchant_state:'',
        merchant_lat:'',
        merchant_long:''}
      })
    );
    console.log('Delete POS Response: ',putResultData)
    setPOSName('None');  
    

  } catch (err) {
    console.log("Error Updating Orders: ",err);
  }
}


  
if (POSName == "None") {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Manage Integrations</IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent >
        <IonList>
          
          <IonItem>
            <IonThumbnail slot="start">
              <img src="assets/images/Clover-POS-Logo.png" />
            </IonThumbnail>

            <IonButton slot="end" target = "_blank" href="https://www.clover.com/oauth/authorize?client_id=HVWQHE0VSP26C&redirect_uri=https://app.bottomlinedeliveries.com/POS_Addons/Clover">
              Sign-in with Clover
            </IonButton>
            <IonLabel>Clover</IonLabel>
          </IonItem>

          
          <IonItem>
            <IonThumbnail slot="start">
              <img src="assets/images/Square-Logo.png" />
            </IonThumbnail>

            <IonButton slot="end" target = "_blank" href="https://connect.squareup.com/oauth2/authorize?client_id=sq0idp-d-yXj97HLsj9r3bJ5CJZZQ&scope=CUSTOMERS_WRITE+CUSTOMERS_READ+ORDERS_READ+ORDERS_WRITE+MERCHANT_PROFILE_READ+SUBSCRIPTIONS_READ+SUBSCRIPTIONS_WRITE+INVOICES_READ+INVOICES_WRITE&session=false&state=82201dd8d83d23cc8a48caf52b">
              Sign-in with Square
            </IonButton>
            <IonLabel>Square </IonLabel>
          </IonItem>
        
        </IonList>
      </IonContent>
    </IonPage>
  );
  } {
    return(
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Manage Integrations</IonTitle>
        </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonItem>
            <IonLabel>POS Already Added</IonLabel>
            <IonButton fill="outline" slot="end" onClick={()=>showAlert({
                                  header: 'Do you want to delete your existing POS?',
                                  message: '',
                                  buttons: [
                                    'Cancel',
                                    { text: 'Yes, delete it.', handler: (d) => {
                                                        console.log('ok pressed')
                                                        deletePOS()
                                                        present('POS has been deleted. Please, connect another one. ', 4000)
                                                      } 
                                    },
                                  ],
                                  onDidDismiss: (e) => console.log('alert dismiss',e),})
                                  }>Delete Exisiting POS</IonButton>
          </IonItem>

        </IonContent>

      </IonPage>
   )

  }
};


export default AddPOS;
function showAlert(arg0: { header: string; message: string; buttons: (string | { text: string; handler: (d: any) => void; })[]; onDidDismiss: (e: any) => void; }): void {
  throw new Error('Function not implemented.');
}

