import { Redirect, Route } from 'react-router-dom';
import {IonApp,IonRouterOutlet,IonSplitPane} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import Tabs from './components/Tabs';
import Menu from './components/Menu';
import LogoutPage from './components/LogoutPage';
import Settings from './components/Settings';
import AddPOS from './components/AddPOS';
import Clover from './POS_Addons/Clover'
import Square from './POS_Addons/Square'


/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';
 
/* Theme variables */
import './theme/variables.css';
import{withAuthenticator} from '@aws-amplify/ui-react';

const App: React.FC = () => (
  <IonApp>
    <IonReactRouter>
      <IonSplitPane contentId="main" when="(min-width: 2000px)">
        <Menu/>
    
        <IonRouterOutlet id="main">
            <Route path="/components/Tabs" exact >
              <Tabs />
            </Route>

            <Route path="/components/Settings" exact>
              <Settings />
            </Route>

            <Route path="/components/LogoutPage" >
              <LogoutPage />
            </Route>

            <Route path="/components/AddPOS" exact>
              <AddPOS />
            </Route>
            
            <Route path="/components/Menu" exact>
              <Menu/>
            </Route>

            <Route path="/POS_Addons/Clover" exact>
              <Clover/>
            </Route>

            <Route path="/POS_Addons/Square" exact>
              <Square/>
            </Route>

            <Route path="/" exact>
              <Redirect to="/tabs/tab1" />
            </Route>

            <Route path="/tabs/tab1" exact  >
              <Redirect to="/components/Tabs" />
            </Route>

            <Route path="/tabs/tab2" exact>
              <Redirect to="/components/Tabs" />
            </Route>

            <Route path="/tabs/tab3" exact>
              <Redirect to="/components/Tabs" />
            </Route>

          </IonRouterOutlet>

      </IonSplitPane>
    </IonReactRouter>
  </IonApp>
  );

export default withAuthenticator(App);
