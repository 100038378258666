import { IonContent, IonHeader,IonButton,IonIcon,IonButtons,IonMenuButton, 
  IonPage, IonTitle, IonToolbar,IonChip,IonLabel } from '@ionic/react';
import './LogoutPage.css';

import { AmplifySignOut} from '@aws-amplify/ui-react';

const LogoutPage: React.FC = () => {


  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Logout</IonTitle>
        
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        
          
        <AmplifySignOut/>
      </IonContent>
    </IonPage>
  );
};


export default LogoutPage;
