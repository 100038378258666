import {IonList,IonItem,IonThumbnail, IonContent, IonHeader,IonButton,
  IonImg,useIonAlert,IonAlert,IonButtons,IonMenuButton, IonPage, IonTitle, IonToolbar,IonChip,IonLabel,IonCard,IonCardContent,IonCardHeader, IonCardSubtitle, IonCardTitle } from '@ionic/react';
import './Clover.css';
import 'axios'

import React, { useEffect, useState } from 'react'
import { API, graphqlOperation } from 'aws-amplify'
import { updateUser } from '../graphql/mutations'
import { listUsers } from '../graphql/queries'
import * as subscriptions from '../graphql/subscriptions';
import Observable from 'zen-observable';
import axios from 'axios';
import {
  useIonViewWillEnter,
} from '@ionic/react';


const Clover: React.FC = () => {
  useIonViewWillEnter(() => {
    console.log('ionViewWillEnter event fired');
  });

  const [awsUserID, setawsUserID] = useState<any[]>([]);
  const [bldUsername,setbldUsername] = useState(null);
  const [POSName, setPOSName] = useState<string[]>([]);


  
  
  // Initial data pull when the view renders
  async function initialQuery() {
    console.log("Adding Clover POS: ");
    try {
      const initialQueryData: any = await API.graphql(graphqlOperation(listUsers));
      console.log("Initial Query Data: ",initialQueryData);
  
      const tmpAWSid = initialQueryData.data.listUsers.items[0].id;
      setawsUserID(tmpAWSid);
      console.log("Initial AWS ID: ",tmpAWSid);

      const tmpbldUsername = initialQueryData.data.listUsers.items[0].username;
      setbldUsername(tmpbldUsername);
      console.log("Initial BLD Username: ",tmpbldUsername);
      
      const search = window.location.search;
      console.log("Info: ", window)
      
      
      const tmp_merchant_id = new URLSearchParams(window.location.search).get('merchant_id');
      const tmp_auth_token = new URLSearchParams(window.location.search).get('code');
      
      console.log("AddPOS Auth Token: ", tmp_auth_token)
      const auth_post = {
        auth_token:tmp_auth_token,
        merchant_id:tmp_merchant_id,
        username:tmpbldUsername,
        pos_name:'Clover',
        aws_id:tmpAWSid
      };
      console.log('data to send: ',auth_post)
    

      if (tmp_auth_token !== null && tmpbldUsername !== null) {
        axios.post('https://l1ac9um4ak.execute-api.us-east-1.amazonaws.com/prod', auth_post)
            .then(response => console.log('Response: ',response))
            .catch(err => console.log(err));
      }

    } catch (err) {
      console.log("Error Fetching Initial Query: ",err);
    }
  }

  useEffect(()=>{
    initialQuery();
  },[]);

  

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle></IonTitle>
        </IonToolbar>
      </IonHeader>

      <IonContent >
      <IonCard>
      <IonCardHeader>
            <IonCardTitle>Successfully Added Clover as your POS!</IonCardTitle>
          </IonCardHeader>

          <IonCardContent>
          If you are using the Clover app, please return to that to finish setup. 

          If you are using the Web app, select 'Delivery Orders' and refresh the page.   
          </IonCardContent>
          
      </IonCard>
      </IonContent>
    </IonPage>
  );
};


export default Clover;
