import './Tab2.css';
import {IonThumbnail, IonGrid,IonRow,IonCol,IonRefresher,IonRefresherContent, useIonAlert,useIonToast, IonItem, IonLabel, IonList,IonMenuButton, IonContent, IonHeader, IonPage, IonTitle, IonToolbar,IonButtons, IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonIcon, IonButton } from '@ionic/react';
import {refresh,chevronDownCircleOutline } from 'ionicons/icons';

import {
  useIonViewDidEnter,
  useIonViewDidLeave,
  useIonViewWillEnter,
  useIonViewWillLeave
} from '@ionic/react';

import React, { useEffect, useState } from 'react'
import  { API, graphqlOperation } from 'aws-amplify'
import { updateUser } from '../graphql/mutations'
import { listUsers,getUser } from '../graphql/queries'
import axios from 'axios';
import * as subscriptions from '../graphql/subscriptions';
import Observable from 'zen-observable';
import QRCode from 'react-qr-code';
import { Auth } from 'aws-amplify';


const Tab2: React.FC = () => {
  
  const [orders, setOrders] = useState<any[]>([]);
  const [merchantID, setMerchantID] = useState<any[]>([]);
  const [readyOrders, setReadyOrders] = useState<any[]>([]);
  const [POSid, setPOSid] = useState<any[]>([]);

  const [showAlert, hideAlert] = useIonAlert();
  const [present, dismiss] = useIonToast();
  

// Ionic app lifecycle
  useIonViewDidEnter(() => {
    //console.log('ionViewDidEnter event fired');
  });

  useIonViewDidLeave(() => {
    //console.log('ionViewDidLeave event fired');
  });

  useIonViewWillEnter(() => {
    refreshReadyData();
    //console.log('ionViewWillEnter event fired');
  });

  useIonViewWillLeave(() => {
    //console.log('ionViewWillLeave event fired');
  });

  
  useEffect(()=>{
    initialQuery();
  },[]);


  // Trying to figure out how to setup subscriptions
  async function initialQuery() {
    //console.log("Initial Query - Tab2: ");
    try {
      const tmpAWSID: any = await Auth.currentUserInfo().then((userInfo)=>{
        //console.log('AUTH USER INFO: ', userInfo.attributes.sub.Type)
        return userInfo.attributes.sub
      })
      // This "any" added to not assess Type for "property data doesn't exist"
      const queryData: any = await API.graphql(graphqlOperation(getUser,{id:tmpAWSID}));

      
      setOrders(queryData.data.getUser.orders);

      // Saving AWS ID to undate, done sloppy for convenience
      const tmpMerchID = queryData.data.getUser.merchant_id;
      setMerchantID(tmpMerchID)

      // Saving AWS ID to undate, done sloppy for convenience
      const tmpPOSid = queryData.data.getUser.pos_name;
      setPOSid(tmpPOSid)

      const tmpOrders:any = queryData.data.getUser.orders;  
      const clusteredOrders = tmpOrders.filter((e: { order_state: string; }) => e.order_state == "1").reduce((r: { [x: string]: any; }, a: { cluster_number: string | number; }, i: any, arr: any) => {
        r[a.cluster_number] = [...r[a.cluster_number] || [],a];
        return r;
      },{});

      setReadyOrders(Object.entries(clusteredOrders));  

    } catch (err) {
      console.log("Error Fetching Initial Query: ",err);
    }
  }

  
  //Refresh button for Ready orders
  async function refreshReadyData() {
    try {
      const tmpAWSID: any = await Auth.currentUserInfo().then((userInfo)=>{
        //console.log('AUTH USER INFO: ', userInfo.attributes.sub)
        return userInfo.attributes.sub
      })
  
      // This "any" added to not assess Type for "property data doesn't exist"
      const queryData: any = await API.graphql(graphqlOperation(getUser,{id:tmpAWSID}));
      //console.log('QUERY DATA: ',queryData)
      setOrders(queryData.data.getUser.orders);

      const clusteredOrders = queryData.data.getUser.orders.filter((e: { order_state: string; }) => e.order_state == "1").reduce((r: { [x: string]: any; }, a: { cluster_number: string | number; }, i: any, arr: any) => {
        r[a.cluster_number] = [...r[a.cluster_number] || [],a];
        return r;
      },{});
      //console.log('URLs',clusteredOrders)
      setReadyOrders(Object.entries(clusteredOrders));
      //console.log('Ready Orders: ', Object.entries(clusteredOrders))
      

    } catch (err) {
      console.log("Error Fetching Query: ",err);
    }
  }

  
  //Refresh button for Ready orders
  async function deleteOrder(response:any,cluster_event:any,allOrders:any) {
    
    try {
      
      const tmpAWSID: any = await Auth.currentUserInfo().then((userInfo)=>{
        //console.log('AUTH USER INFO: ', userInfo.attributes.sub)
        return userInfo.attributes.sub
      })

      //console.log('Response: ', response)
      //console.log('Cluser Event: ', cluster_event)
      
      // Filter out the deleted cluster
      const unchangedOrders = allOrders.filter((e: { cluster_number: any; }) => e.cluster_number !== cluster_event[0]);
      //console.log('Test 1: ',unchangedOrders)
      setOrders(unchangedOrders);
      
      const clusteredOrders = unchangedOrders.filter((e: { order_state: string; }) => e.order_state == "1").reduce((r: { [x: string]: any; }, a: { cluster_number: string | number; }) => {
        r[a.cluster_number] = [...r[a.cluster_number] || [],a];
        return r;
      },{});
      setReadyOrders(Object.entries(clusteredOrders))
      
      const putResultData: any = await API.graphql(
        graphqlOperation(updateUser, {
          input: {"id":tmpAWSID,
          orders: unchangedOrders}
        })
      );
      //console.log('Order Deleted: ', putResultData)
      
    } catch (err) {
      console.log("Error Fetching Query: ",err);
    }
  }
  
  
//useEffect(()=>{console.log("useEffect Ready Orders:",readyOrders)},[readyOrders]);

// Change from Ready to Delivered
  async function printOrder(cluster_event:any) {
    console.log("------------------------------");
    try {
      
      const tmpAWSID: any = await Auth.currentUserInfo().then((userInfo)=>{
        //console.log('AUTH USER INFO: ', userInfo.attributes.sub)
        return userInfo.attributes.sub
      })

      if (cluster_event[0] !== "0") {
        //Get all ready Orders
        const send_event = {
          aws_id:tmpAWSID,
          merchant_id:merchantID,
          orders:cluster_event,
          pos_name:POSid
        }
        //console.log(send_event)
        const allOrders = [...orders];

        axios.post('https://bx8dv7d740.execute-api.us-east-1.amazonaws.com/dev/BLD_Router_Delete_Order', send_event)
            .then(response => deleteOrder(response,cluster_event,allOrders) )
            .catch(err => console.log('Error Printing Orders: ',err));
          
      }
      
    } catch (err) {
      console.log("Error Updating Orders: ",err);
    }
  }
  

  
// Subscriptions to new user data
  useEffect(()=>{
    
    const subData = API.graphql(graphqlOperation(subscriptions.updatedOrder));
    if (subData instanceof Observable) {
      const sub = subData.subscribe({
        next: (newData) => {
          const tmpOrders = newData.value.data.updatedOrder.orders
          const clusteredOrders = tmpOrders.filter((e: { order_state: string; }) => e.order_state == "1").reduce((r: { [x: string]: any; }, a: { cluster_number: string | number; }, i: any, arr: any) => {
            r[a.cluster_number] = [...r[a.cluster_number] || [],a];
            return r;
          },{});
          
          setReadyOrders(Object.entries(clusteredOrders));
          setOrders(tmpOrders);
        },
        error(err) { console.log(`Finished with error: ${ err }`) },
        complete() { console.log('Finished') }
      });
    
    return()=>{
      sub instanceof Observable && sub.unsubscribe()
    };
  }
  },[]);
  

  
  
  
  return (
    <IonPage>

      {/* Header: title and refresh button for the Ready Orders */}
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>

          <IonTitle>Ready Orders</IonTitle>
          
          <IonButton fill="outline" slot="end" onClick={refreshReadyData}>
            <IonIcon icon={refresh} />
          </IonButton>
        </IonToolbar>
      </IonHeader>

      <IonContent>
      

  {/*Content: Grouped by clusters with a delete button on the cluster WTF*/}
      { readyOrders.map((item,index)=>{
        return(
          <IonCard key={item ? item:index}>
            <IonItem >
                <IonCardHeader>
                  <IonCardTitle>Group: {item[0]}</IonCardTitle>
                </IonCardHeader>
                <IonButton fill="outline" slot="end" 
                        onClick={()=>showAlert({
                                  header: 'Do you want to deliver these orders?',
                                  message: 'Cluster Number:  '+item[0],
                                  buttons: [
                                    'Cancel',
                                    { text: 'Yes, print receipt.', handler: (d) => {
                                                        console.log('ok pressed',item)
                                                        printOrder(item)
                                                        present({
                                                          buttons: [{ text: 'Hide', handler: () => dismiss() }],
                                                          message: 'Group: '+item[0]+' has been taken out.',
                                                          duration:3000,
                                                          onDidDismiss: () => console.log('dismissed'),
                                                          onWillDismiss: () => console.log('will dismiss'),
                                                        })
                                                      }
                                                       
                                    },
                                  ],
                                  
                                  onDidDismiss: (e) => console.log('alert dismiss',e),})
                                  }
                                  >Print</IonButton>  
            </IonItem>

            
              <IonCardContent>
              <IonGrid>
                <IonRow>
                  <IonCol size="8">
                  <IonList>
                    {item[1].map((child_item:any,child_index:any)=>{
                      return(
                    <IonCard>
                    <IonItem key = {child_item.order_id?child_item.order_id:child_index}>
                      <IonLabel>
                        <h2>Order Number : {child_item.order_id}</h2>
                        <h3>Name: {child_item.customer}</h3>
                        <h4>Address: {child_item.street}, {child_item.city} {child_item.state} {child_item.zipCode}</h4>
                      </IonLabel>
                    </IonItem>
                    </IonCard>
                      )})
                    }
                  </IonList>
                  </IonCol>
                  <IonCol class="ion-align-items-end">
                  
                    <QRCode value={item[1][0].directions_url}/>
      
                  </IonCol>
                  </IonRow>
                </IonGrid>
              </IonCardContent>
            
          </IonCard>
        )})
      }
      
       </IonContent>
    </IonPage>

  );
};

export default Tab2;
