/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      username
      email
      phone
      pos_name
      orders {
        order_id
        customer
        street
        cluster_number
        city
        order_state
        zipCode
        lat
        long
        total
        state
        created_time
        str_created_time
        directions_url
        restaurant_distance
      }
      merchant_id
      cluster_type_number
      merchant_street
      merchant_city
      merchant_state
      merchant_zipCode
      merchant_lat
      merchant_long
      max_cluster_size
      min_cluster_size
      number_of_drivers
      createdAt
      updatedAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      username
      email
      phone
      pos_name
      orders {
        order_id
        customer
        street
        cluster_number
        city
        order_state
        zipCode
        lat
        long
        total
        state
        created_time
        str_created_time
        directions_url
        restaurant_distance
      }
      merchant_id
      cluster_type_number
      merchant_street
      merchant_city
      merchant_state
      merchant_zipCode
      merchant_lat
      merchant_long
      max_cluster_size
      min_cluster_size
      number_of_drivers
      createdAt
      updatedAt
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      username
      email
      phone
      pos_name
      orders {
        order_id
        customer
        street
        cluster_number
        city
        order_state
        zipCode
        lat
        long
        total
        state
        created_time
        str_created_time
        directions_url
        restaurant_distance
      }
      merchant_id
      cluster_type_number
      merchant_street
      merchant_city
      merchant_state
      merchant_zipCode
      merchant_lat
      merchant_long
      max_cluster_size
      min_cluster_size
      number_of_drivers
      createdAt
      updatedAt
    }
  }
`;
