import './Tab1.css';
import {useIonToast,useIonAlert,IonList, IonItem,IonMenuButton, IonButtons , IonContent, IonHeader, IonPage, IonTitle, IonToolbar,IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonIcon, IonButton  } from '@ionic/react';
import {refresh } from 'ionicons/icons';

import {
  useIonViewDidEnter,
  useIonViewDidLeave,
  useIonViewWillEnter,
  useIonViewWillLeave
} from '@ionic/react';

import React, { useEffect, useState } from 'react'
import Amplify, { API, graphqlOperation } from 'aws-amplify'
import { updateUser } from '../graphql/mutations'
import { listUsers, getUser } from '../graphql/queries'
import * as subscriptions from '../graphql/subscriptions';
import Observable from 'zen-observable';
import { Auth } from 'aws-amplify';


export const Tab1: React.FC = () => {
  
  const [awsUserID, setawsUserID] = useState<any>();
  const [orders, setOrders] = useState<any[]>([]);
  const [readyOrders, setReadyOrders] = useState<any[]>([]);
  const [showAlert, hideAlert] = useIonAlert();
  const [present, dismiss] = useIonToast();
  
// Ionic app lifecycle
  useIonViewDidEnter(() => {
    //console.log('ionViewDidEnter event fired');
  });

  useIonViewDidLeave(() => {
    //console.log('ionViewDidLeave event fired');
  });

  useIonViewWillEnter(() => {
    //refreshData();
    //console.log('ionViewWillEnter event fired');
  });

  useIonViewWillLeave(() => {
    //console.log('ionViewWillLeave event fired');
  });

  // Initial data pull when the view renders
  async function initialQuery() {
    //console.log("Initial Query: ");
    try {
      const tmpAWSID: any = await Auth.currentUserInfo().then((userInfo)=>{
        //console.log('AUTH USER INFO: ', userInfo.attributes.sub.Type)
        setawsUserID(awsUserID)
        return userInfo.attributes.sub
      })
      const initialQueryData: any = await API.graphql(graphqlOperation(getUser,{id:tmpAWSID}));
      //console.log('Initial Query Data: ',initialQueryData)

      setOrders(initialQueryData.data.getUser.orders);

      const clusteredOrders = orders.reduce((r, a, i, arr) => {
        r[a.cluster_number] = [...r[a.cluster_number] || [],a];
        return r;
      },{});
      setReadyOrders(Object.entries(clusteredOrders));
  

    } catch (err) {
      console.log("Error Fetching Initial Query: ",err);
    }
  }

  useEffect(()=>{
    initialQuery();
  },[]);
  


// Query/Refresh all the orders
async function refreshData() {
  console.log("Get Query pressed.");
  try {
    const tmpAWSID: any = await Auth.currentUserInfo().then((userInfo)=>{
     // console.log('AUTH USER INFO: ', userInfo.attributes.sub)
      setawsUserID(awsUserID)
      return userInfo.attributes.sub
    })

    // This "any" added to not assess Type for "property data doesn't exist"
    const queryData: any = await API.graphql(graphqlOperation(getUser,{id:tmpAWSID}));
    console.log('QUERY DATA: ',queryData)
    setOrders(queryData.data.getUser.orders);

    const clusteredOrders = orders.reduce((r, a, i, arr) => {
      r[a.cluster_number] = [...r[a.cluster_number] || [],a];
      return r;
    },{});
    setReadyOrders(Object.entries(clusteredOrders));

  } catch (err) {
    console.log("Error Fetching Query: ",err);
  }
}

// Change from In-Progress to Ready
  async function changeToReady(order_event:any) {
    console.log("changeToReady button pressed.");
    try {
      const tmpAWSID: any = await Auth.currentUserInfo().then((userInfo)=>{
        //console.log('AUTH USER INFO: ', userInfo.attributes.sub.Type)
        setawsUserID(awsUserID)
        return userInfo.attributes.sub
      })
      //Get all Orders
      const allOrders = [...orders];

      // Delete changed Order
      const unchangedOrders = allOrders.filter(e => e.order_id !== order_event.order_id);

      // Change updated order to "Ready"
      order_event.order_state = "1";

      // Add the Order back to the orders
      const tmpOrders = [...unchangedOrders,order_event];

      // Change the state orders
      setOrders(tmpOrders)

      //console.log('AWS ID',tmpAWSID)

      //Update in AWS
      const putResultData: any = await API.graphql(
        graphqlOperation(updateUser, {
          input: {"id":tmpAWSID,
          orders: tmpOrders}
        })
      );
      console.log('In-Progress moved to Ready: ',putResultData)

    } catch (err) {
      console.log("Error Updating Orders: ",err);
    }
  }

  // Subscriptions to new data
  useEffect(()=>{
    
    const subData = API.graphql(graphqlOperation(subscriptions.updatedOrder));
    if (subData instanceof Observable) {
      const sub = subData.subscribe({
        next: (newData) => {
          console.log('IN-Progress Subscription: ',newData.value.data.updatedOrder.orders)
          
          setOrders(newData.value.data.updatedOrder.orders);
        },
        error(err) { console.log(`Finished with error: ${ err }`) },
        complete() { console.log('Finished') }
      });
    
    //setOrders(subData.data.listUsers.items);
    //console.log("Results: ",orders)

    return() => sub.unsubscribe()
    }
  },[]);
  
    
   


  return (
    <IonPage>
      {/* Header section with a title and a refresh button to query 'In-progress data */}
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          
          <IonTitle>In-Progress Orders</IonTitle>
          <IonButton fill="outline" slot="end" onClick={refreshData}>
            <IonIcon icon={refresh} />
          </IonButton>
        </IonToolbar>
      </IonHeader>

      {/* Content section with be mapped to the following format */}
      <IonContent>
      
      <IonList>
        {/********** Extra Cards Below: First is the experimental card *********/}
        { orders.filter(e => e.order_state == "0").map((item,index)=>{
            return (
              <IonCard>
                <IonItem key={item.order_id }>
                  <IonCardHeader>
                    <IonCardTitle>Order Number: {item.order_id}</IonCardTitle>
                    <IonCardSubtitle>Name: {item.customer}</IonCardSubtitle>
                    <IonCardSubtitle>Address: {item.street}, {item.city} {item.state} {item.zipCode}</IonCardSubtitle>
                  </IonCardHeader>

                  <IonButton size="large" fill="outline" slot="end" 
                    onClick={()=>showAlert({
                                  header: 'Is this order ready?',
                                  message: 'Order Number: '+item.order_id,
                                  buttons: [
                                    'Cancel',
                                    { text: 'Yes, send it out.', handler: (d) => {
                                                        console.log('ok pressed',item)
                                                        changeToReady(item)
                                                        present({
                                                          buttons: [{ text: 'Hide', handler: () => dismiss() }],
                                                          message: 'Order: "'+item.order_id+'" for "'+item.customer +'" is Ready.',
                                                          duration:3000,
                                                          onDidDismiss: () => console.log('dismissed'),
                                                          onWillDismiss: () => console.log('will dismiss'),
                                                        })
                                                      } 
                                    },
                                  ],
                                  onDidDismiss: (e) => console.log('alert dismiss',e),})
                                  } > Ready </IonButton>
                </IonItem>
              </IonCard>
              )
          })
        }
        </IonList>

      </IonContent>
    </IonPage>
  );
};


export default Tab1;
