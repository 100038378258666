import {
    IonContent,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonListHeader,
    IonMenu,
    IonMenuToggle,
    IonNote,
  } from '@ionic/react';
  
  import { useLocation } from 'react-router-dom';
  import { fastFoodOutline,fastFoodSharp, logOutOutline,logOutSharp,cogOutline,cogSharp, bagAddOutline, bagAddSharp } from 'ionicons/icons';
  import './Menu.css';
  
  interface AppPage {
    url: string;
    iosIcon: string;
    mdIcon: string;
    title: string;
  }
  
  const appPages: AppPage[] = [
    {
      title: 'Delivery Orders',
      url: '/components/Tabs',
      iosIcon: fastFoodOutline,
      mdIcon: fastFoodSharp
    },
    {
      title: 'Settings',
      url: '/components/Settings',
      iosIcon: cogOutline,
      mdIcon: cogSharp
    },
    {
      title: 'Integrations',
      url: '/components/AddPOS',
      iosIcon: bagAddOutline,
      mdIcon: bagAddSharp
    },
    {
      title: 'Logout',
      url: '/components/LogoutPage',
      iosIcon: logOutOutline,
      mdIcon: logOutSharp
    }
  ];
  
   
  const Menu: React.FC = () => {
    const location = useLocation();
    
    
    return (

      <IonMenu contentId="main" type="overlay" swipeGesture={true}>
        <IonContent> 

          <IonList id="inbox-list">
            <IonListHeader>BottomLine Deliveries</IonListHeader>
            <IonNote>support@bottomlinedeliveries.com</IonNote>
            {appPages.map((appPage, index) => {
              return (
                <IonMenuToggle key={index} autoHide={false}>
                  <IonItem className={location.pathname === appPage.url ? 'selected' : ''} routerLink={appPage.url} routerDirection="none" lines="none" detail={false}>
                    <IonIcon slot="start" ios={appPage.iosIcon} md={appPage.mdIcon} />
                    <IonLabel>{appPage.title}</IonLabel>
                  </IonItem>
                </IonMenuToggle>
              );
            })}
          </IonList>
  
        </IonContent>
      </IonMenu>
    );
  };
  
  export default Menu;
  