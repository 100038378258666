/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const updatedOrder = /* GraphQL */ `
  subscription UpdatedOrder {
    updatedOrder {
      id
      username
      email
      phone
      pos_name
      orders {
        order_id
        customer
        street
        cluster_number
        city
        order_state
        zipCode
        lat
        long
        total
        state
        created_time
        str_created_time
        directions_url
        restaurant_distance
      }
      merchant_id
      cluster_type_number
      merchant_street
      merchant_city
      merchant_state
      merchant_zipCode
      merchant_lat
      merchant_long
      max_cluster_size
      min_cluster_size
      number_of_drivers
      createdAt
      updatedAt
    }
  }
`;
export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser($username: String) {
    onCreateUser(username: $username) {
      id
      username
      email
      phone
      pos_name
      orders {
        order_id
        customer
        street
        cluster_number
        city
        order_state
        zipCode
        lat
        long
        total
        state
        created_time
        str_created_time
        directions_url
        restaurant_distance
      }
      merchant_id
      cluster_type_number
      merchant_street
      merchant_city
      merchant_state
      merchant_zipCode
      merchant_lat
      merchant_long
      max_cluster_size
      min_cluster_size
      number_of_drivers
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser($username: String) {
    onUpdateUser(username: $username) {
      id
      username
      email
      phone
      pos_name
      orders {
        order_id
        customer
        street
        cluster_number
        city
        order_state
        zipCode
        lat
        long
        total
        state
        created_time
        str_created_time
        directions_url
        restaurant_distance
      }
      merchant_id
      cluster_type_number
      merchant_street
      merchant_city
      merchant_state
      merchant_zipCode
      merchant_lat
      merchant_long
      max_cluster_size
      min_cluster_size
      number_of_drivers
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser($username: String) {
    onDeleteUser(username: $username) {
      id
      username
      email
      phone
      pos_name
      orders {
        order_id
        customer
        street
        cluster_number
        city
        order_state
        zipCode
        lat
        long
        total
        state
        created_time
        str_created_time
        directions_url
        restaurant_distance
      }
      merchant_id
      cluster_type_number
      merchant_street
      merchant_city
      merchant_state
      merchant_zipCode
      merchant_lat
      merchant_long
      max_cluster_size
      min_cluster_size
      number_of_drivers
      createdAt
      updatedAt
    }
  }
`;
