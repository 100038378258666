import { Redirect, Route } from 'react-router-dom';
import {
  IonApp,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs, IonMenu, IonHeader, IonItem,IonContent,IonToolbar,IonTitle,IonList
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { syncCircleOutline, mapOutline, bagCheckOutline } from 'ionicons/icons';
import Tab1 from '../tabs/Tab1';
import Tab2 from '../tabs/Tab2';
import Tab3 from '../tabs/Tab3';

/** */

const Tabs: React.FC = () => (
  <IonApp>
    <IonReactRouter>
      <IonTabs>

        <IonRouterOutlet>
          <Route path="/tabs/tab1" exact>
            <Tab1 />
          </Route>
          <Route path="/tabs/tab2" exact >
            <Tab2 />
          </Route>
          <Route path="/tabs/tab3" exact>
            <Tab3 />
          </Route>
          <Route path="/components/Tabs" exact>
            <Redirect to="/tabs/tab1" />
          </Route>

        </IonRouterOutlet>

        <IonTabBar slot="bottom">
          <IonTabButton tab="tab1" href="/tabs/tab1">
            <IonIcon icon={syncCircleOutline} />
            <IonLabel>In-Progress</IonLabel>
          </IonTabButton>
          <IonTabButton tab="tab2" href="/tabs/tab2">
            <IonIcon icon={bagCheckOutline} />
            <IonLabel>Ready</IonLabel>
          </IonTabButton>
          <IonTabButton tab="tab3" href="/tabs/tab3">
            <IonIcon icon={mapOutline} />
            <IonLabel>Map</IonLabel>
          </IonTabButton>
        </IonTabBar>
        
      </IonTabs>
    </IonReactRouter>
  </IonApp>
);

export default Tabs;
