import {IonNote, IonContent, IonHeader, IonItemDivider, IonItemGroup, IonPage, IonTitle, IonToolbar, IonSegment, IonSegmentButton } from '@ionic/react';
import './Settings.css';
import { IonListHeader,IonList, IonItem,IonMenuButton, IonButtons, IonLabel, IonInput, IonToggle, IonRadio, IonCheckbox, IonItemSliding, IonItemOption, IonItemOptions } from '@ionic/react';

import {IonRefresherContent, IonRefresher, IonCard, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCardContent, IonIcon, IonButton } from '@ionic/react';
import { RefresherEventDetail } from '@ionic/core';
import {refresh , pin, wifi, wine, warning, walk } from 'ionicons/icons';

import {
  useIonViewDidEnter,
  useIonViewDidLeave,
  useIonViewWillEnter,
  useIonViewWillLeave
} from '@ionic/react';

import React, { useEffect, useState } from 'react'
import Amplify, { API, graphqlOperation } from 'aws-amplify'
import { updateUser } from '../graphql/mutations'
import { listUsers } from '../graphql/queries'

export const Settings: React.FC = () => {
  
  const [userData, setUserData] = useState<any>('');

// Ionic app lifecycle
  useIonViewDidEnter(() => {
    //console.log('ionViewDidEnter event fired');
  });

  useIonViewDidLeave(() => {
    //console.log('ionViewDidLeave event fired');
  });

  useIonViewWillEnter(() => {
   // console.log('ionViewWillEnter event fired');
    getQuery();
  });

  useIonViewWillLeave(() => {
    //console.log('ionViewWillLeave event fired');
  });

  useEffect(()=>{
    getQuery()
  },[]);

// Query for all the orders
async function getQuery() {
  try {
    // This "any" added to not assess Type for "property data doesn't exist"
    const queryData: any = await API.graphql(graphqlOperation(listUsers));

    const tmpUsername = queryData.data.listUsers.items[0];
    setUserData(tmpUsername);    

  } catch (err) {
    console.log("Error Fetching Query: ",err);
  }
}


// Change from Ready to Delivered
async function changeClusterType(event_cluster_type:any) {
  //console.log("------------------------------");
  try {
    const tmpawsUserID = userData.id;
    
    const putResultData: any = await API.graphql(
      graphqlOperation(updateUser, {
        input: {"id":tmpawsUserID,
        cluster_type_number:event_cluster_type}
      })
    );
   

  } catch (err) {
    console.log("Error Updating Orders: ",err);
  }
}

// Change from Ready to Delivered
async function changeMaxClusterSize(event_max_cluster_size:any) {
  //console.log("------------------------------");
  try {
    const tmpawsUserID = userData.id;
    
    const putResultData: any = await API.graphql(
      graphqlOperation(updateUser, {
        input: {"id":tmpawsUserID,
        max_cluster_size:event_max_cluster_size}
      })
    );
   

  } catch (err) {
    console.log("Error Updating Orders: ",err);
  }
}


  return (
    <IonPage>
      {/* Header section with a title and a refresh button to query 'In-progress data */}
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          
          <IonTitle>Settings</IonTitle>
          <IonButton fill="outline" slot="end" onClick={getQuery}>
            <IonIcon icon={refresh} />
          </IonButton>
        </IonToolbar>
      </IonHeader>

      {/* Content section with be mapped to the following format */}
      <IonContent>
      <IonList>
        <IonItemGroup>
          
          <IonItemDivider>
            <IonLabel>Bottomline Deliveries' Username </IonLabel>
          </IonItemDivider>
          <IonItem>
            <IonLabel>{userData.username !=='' && userData.username}</IonLabel>
          </IonItem>

          <IonItemDivider>
            <IonLabel>Restaruant ID </IonLabel>
          </IonItemDivider>
          <IonItem >
            <IonLabel>{userData.merchant_id !=='' && userData.merchant_id}</IonLabel>
          </IonItem>

          <IonItemDivider>
            <IonLabel>Restaurant Location </IonLabel>
          </IonItemDivider>
          <IonItem >
            <IonLabel>{userData.merchant_street !=='' ? (userData.merchant_street+', '+userData.merchant_city+' '+userData.merchant_state+' '+userData.merchant_zipCode) : 'None'}</IonLabel>
          </IonItem>


          <IonItemDivider>
            <IonLabel>POS Connected </IonLabel>
          </IonItemDivider>
          <IonItem >
            <IonLabel>{userData.pos_name !== '' ? userData.pos_name : ''}</IonLabel>
          </IonItem>

          <IonItemDivider>
            <IonLabel>Grouping Type </IonLabel>
          </IonItemDivider>
          <IonItem >
            <IonSegment onIonChange={e => (
              console.log('Segment selected', e.detail.value,
              changeClusterType(e.detail.value))
            )} >

              <IonSegmentButton value='0'>
                <IonLabel>None</IonLabel>
              </IonSegmentButton>

              <IonSegmentButton value='1'>
                <IonLabel>Regular</IonLabel>
              </IonSegmentButton>
             
            </IonSegment>
          </IonItem>


          <IonItemDivider>
            <IonLabel>Maximum Group Size </IonLabel>
          </IonItemDivider>
          <IonItem >
            <IonSegment onIonChange={e => (
              console.log('Segment selected', e.detail.value,
              changeMaxClusterSize(e.detail.value))
            )} >

              <IonSegmentButton value='2'>
                <IonLabel>2</IonLabel>
              </IonSegmentButton>

              <IonSegmentButton value='3'>
                <IonLabel>3</IonLabel>
              </IonSegmentButton>

              <IonSegmentButton value='4'>
                <IonLabel>4</IonLabel>
              </IonSegmentButton>
             
            </IonSegment>
          </IonItem>


        </IonItemGroup>
      </IonList>

      </IonContent>
    </IonPage>
  );
};


export default Settings;
