import './Tab3.css';
import { IonHeader,IonButton,IonIcon,IonButtons,IonMenuButton, IonPage, IonTitle, IonToolbar,IonChip,IonLabel } from '@ionic/react';
import {Map, Marker, ZoomControl}from 'pigeon-maps';
import {refresh  } from 'ionicons/icons';


import {
  useIonViewDidEnter,
  useIonViewDidLeave,
  useIonViewWillEnter,
  useIonViewWillLeave
} from '@ionic/react';

import React, { useEffect, useState } from 'react'
import Amplify, { API, graphqlOperation } from 'aws-amplify'
import { listUsers,getUser } from '../graphql/queries'
import { Auth } from 'aws-amplify';

const Tab3: React.FC = () => {

  const [merchLat, setMerchLat] = useState<string>('');
  const [merchLong, setMerchLong] = useState<string>('');
  //const center = {"lat":40.2561183,"lng":-75.62987849999999};
  const [orders, setOrders] = useState<any[]>([]);
  const [readyOrders, setReadyOrders] = useState<any[]>([]);

// Ionic app lifecycle
  useIonViewDidEnter(() => {
    //console.log('ionViewDidEnter event fired');
  });

  useIonViewDidLeave(() => {
    //console.log('ionViewDidLeave event fired');
  });

  useIonViewWillEnter(() => {
    refreshData();
    //console.log('ionViewWillEnter event fired');
  });

  useIonViewWillLeave(() => {
    //console.log('ionViewWillLeave event fired');
  });

  function colorHandler(cluster_number:any) {
    const colors = ['red', 'green', 'blue', 'orange', 'yellow','darkgrey','darkgreen','deeppink','indigo','paleturquoise'];
    const assignedColor = colors[(cluster_number % 10)] ;

    return assignedColor;
  };

  async function refreshData() {
    //console.log("Map Data refreshed.");
    try {
      
      const tmpAWSID: any = await Auth.currentUserInfo().then((userInfo)=>{
        //console.log('AUTH USER INFO: ', userInfo.attributes.sub.Type)
        return userInfo.attributes.sub
      })

      const queryData: any = await API.graphql(graphqlOperation(getUser,{id:tmpAWSID}));

      const tmpMerchLat = queryData.data.getUser.merchant_lat;
      setMerchLat(tmpMerchLat)

      const tmpMerchLong = queryData.data.getUser.merchant_long;
      setMerchLong(tmpMerchLong)


      const tmpAWSid = queryData.data.getUser.id;
      setOrders(queryData.data.getUser.orders);
  
      const clusteredOrders = orders.filter(e => e.order_state !== "0").reduce((r, a, i, arr) => {
        r[a.cluster_number] = [...r[a.cluster_number] || [],a];
        return r;
      },{});

      setReadyOrders(Object.entries(clusteredOrders));
  
    } catch (err) {
      console.log("Error Fetching Query: ",err);
    }
  }

  
  useEffect(()=>{
    refreshData();
  },[]);


  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonTitle>Map</IonTitle>
          <IonButton fill="outline" slot="end" onClick={refreshData}>
            <IonIcon icon={refresh} />
          </IonButton>
        </IonToolbar>
      </IonHeader>
          <Map  
            defaultCenter={merchLat !== '' ? [parseFloat(merchLat),parseFloat(merchLong)] : [39.9526,-75.1652]} 
            defaultZoom={11}>
            <Marker 
                  width={35} 
                  anchor={merchLat!=='' ? [parseFloat(merchLat),parseFloat(merchLong)]: [0,0]}
                  color = {'black'}
                  onClick={()=> console.log('Restaurant Location: ',merchLat,' and ',merchLong)}
                />
            {orders.filter(e => e.order_state == "1").map((item,index)=>{
              return(
                <Marker 
                  key = {item.order_id} 
                  width={50} 
                  anchor={[parseFloat(item.lat),parseFloat(item.long)]}
                  color = {colorHandler(item.cluster_number)}
                  onClick={()=> console.log('Information: '+'\n'+` Address: `,item.street,'\n','Cluster Number: ',item.cluster_number)}
                />
              )})
            }
            <ZoomControl/>
          </Map>
    </IonPage>
  );
};


export default Tab3;
